<script>
import DetailSectionComponent from '@/components/VehicleCard/components/DetailSection/DetailSectionComponent';
export default {
  name: 'Fuel',
  components: {
    DetailSectionComponent,
  },
  props: {
    isElectric: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<template>
  <DetailSectionComponent
    :title="isElectric ? $t('tariff.fuel.electric_title') : $t('tariff.fuel.default_title')"
    :icon-props="{
      icon: isElectric ? ICONS.batteryRecharge : ICONS.fuelDispenser,
      size: ICONS_SIZES.small,
      color: GRAYSCALE.ink
    }"
  >
    <p class="mb-3">
      <span
        v-if="!isElectric"
        class="d-block mb-1"
      >
        {{ $t('tariff.fuel.fuel_included') }}
      </span>
      <span class="emobg-color-ink-light">{{ isElectric ? $t('tariff.fuel.electric_text') : $t('tariff.fuel.default_text') }}</span>
    </p>
  </DetailSectionComponent>
</template>
