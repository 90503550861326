<script>
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import DetailSectionComponent from '@/components/VehicleCard/components/DetailSection/DetailSectionComponent';
import {
  extraMileageText,
  freeMileageText,
  hasAllRangesSamePrice,
  hasFreeMileage,
  hasNotFreeMileage,
  hasUnlimitedMileage,
} from '../helpers.const';

export default {
  name: 'MileageDetails',
  components: {
    DetailSectionComponent,
  },
  props: {
    tariff: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ranges() {
      const ranges = cloneDeep(get(this, 'tariff.mileage_price_ranges', []));

      if (ranges.length && get(ranges, '[0].price_raw') === 0 && get(ranges, '[0].start_range') === 0) {
        ranges.shift();
      }

      return ranges;
    },
  },
  created() {
    this.isMileageIncluded = hasUnlimitedMileage(this.tariff);
    this.hasFreeMileage = hasFreeMileage(this.tariff);
    this.hasNotFreeMileage = hasNotFreeMileage(this.tariff);
    this.hasAllRangesSamePrice = hasAllRangesSamePrice(this.tariff);
    this.freeMileageText = freeMileageText(this.tariff);
    this.extraMileageText = extraMileageText(this.tariff);
  },
  methods: {
    getRangeText(range) {
      const start = get(range, 'start_range');
      const end = get(range, 'end_range');
      const units = get(range, 'units');
      const label = end !== null
        ? this.$t('tariff.mileage_details.default_from_label', { start, end, units })
        : this.$t('tariff.mileage_details.last_range_label', { units });

      const value = get(range, 'price_raw') === 0
        ? this.$t('tariff.mileage_details.free_range_value')
        : `${get(range, 'price')}/${units}`;

      return `${label}: ${value}`;
    },
  },
};
</script>
<template>
  <DetailSectionComponent
    :title="$t('tariff.mileage_details.title')"
    :icon-props="{
      icon: ICONS.mileage,
      size: ICONS_SIZES.small,
      color: GRAYSCALE.ink
    }"
    class="mb-4"
  >
    <div
      v-if="isMileageIncluded"
      v-text="$t('tariff.price_component.unlimited_text')"
    />
    <div
      v-else-if="hasFreeMileage"
      v-text="freeMileageText"
    />
    <div
      v-if="hasAllRangesSamePrice || hasNotFreeMileage"
      class="emobg-font-small emobg-color-ink-light"
      v-text="extraMileageText"
    />
    <div
      v-for="(range, index) in ranges"
      v-else-if="!isMileageIncluded"
      :key="index"
      class="emobg-font-small emobg-color-ink-light"
    >
      {{ getRangeText(range) }}
    </div>
  </DetailSectionComponent>
</template>
