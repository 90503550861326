<script>
import get from 'lodash/get';
import filter from 'lodash/filter';
import last from 'lodash/last';
import find from 'lodash/find';
import { DISTANCE_UNIT } from '@emobg/web-utils';
import {
  extraMileageText,
  freeMileageText,
  hasFreeMileage,
  hasUnlimitedMileage,
} from '../TariffInformation/helpers.const';

export default {
  name: 'PriceComponent',
  props: {
    tariff: {
      type: Object,
      default: null,
    },
    pricePerDay: {
      type: Boolean,
      default: false,
    },
    mileageSymbol: {
      type: String,
      default: DISTANCE_UNIT.kilometers,
    },
  },
  computed: {
    hasUnlimitedMileage() {
      return hasUnlimitedMileage(this.tariff);
    },
    hasFreeMileage() {
      return hasFreeMileage(this.tariff);
    },
    freeMileageText() {
      return freeMileageText(this.tariff);
    },
    pricedRanges() {
      const ranges = get(this, 'tariff.mileage_price_ranges', []);
      return filter(ranges, ({ price_raw: price }) => price !== 0);
    },
    extraMileageText() {
      return extraMileageText(this.tariff);
    },
    freeMiddleRangeText() {
      const ranges = get(this, 'tariff.mileage_price_ranges', []);
      const middleRange = find(ranges, (range, index) => {
        const isFirstOrLast = (index === 0 || index === ranges.length - 1);
        return !isFirstOrLast && get(range, 'price_raw') === 0;
      });

      const start = get(middleRange, 'start_range');
      const end = get(middleRange, 'end_range');
      const units = get(middleRange, 'units');

      return middleRange ? this.$t('tariff.price_component.free_middle_range_text', { start, end, units }) : '';
    },
    freeLastRangeText() {
      const ranges = get(this, 'tariff.mileage_price_ranges', []);
      const lastRange = last(ranges);

      return ranges.length > 1 && get(lastRange, 'price_raw') === 0
        ? this.$t('tariff.price_component.free_last_range_text', {
          start: get(lastRange, 'start_range'),
          units: get(lastRange, 'units'),
        })
        : '';
    },
  },
};
</script>

<template>
  <div
    v-if="tariff"
    v-once
  >
    <div
      v-if="hasUnlimitedMileage"
      class="emobg-font-small emobg-color-ink-light"
      v-text="$t('tariff.price_component.unlimited_text')"
    />
    <div
      v-if="!hasUnlimitedMileage && hasFreeMileage"
      class="emobg-font-small emobg-color-ink-light"
      v-text="freeMileageText"
    />
    <template v-if="pricedRanges.length">
      <div
        v-if="extraMileageText"
        class="emobg-font-small emobg-color-ink-light"
        v-text="extraMileageText"
      />
      <div
        v-if="freeMiddleRangeText"
        class="emobg-font-small emobg-color-ink-light"
        v-text="freeMiddleRangeText"
      />
      <div
        v-if="freeLastRangeText"
        class="emobg-font-small emobg-color-ink-light"
        v-text="freeLastRangeText"
      />
    </template>
  </div>
</template>
