<script>
import get from 'lodash/get';
import DetailSectionComponent from '@/components/VehicleCard/components/DetailSection/DetailSectionComponent';
export default {
  name: 'FareInformation',
  components: {
    DetailSectionComponent,
  },
  props: {
    tariff: {
      type: Object,
      required: true,
    },
  },
  methods: {
    get,
  },
};
</script>
<template>
  <DetailSectionComponent
    :title="$t('tariff.fare_information.title')"
    :icon-props="{
      icon: ICONS.time,
      size: ICONS_SIZES.small,
      color: GRAYSCALE.ink
    }"
    class="FareInformation mb-4"
  >
    <div class="d-flex justify-content-between">
      <p class="FareInformation__fare mb-3 mr-4 flex-grow-1">
        {{ $t('tariff.fare_information.hour_fare_title') }}: {{ $t('tariff.information.price_per_hour', { price: get(tariff, 'hourly_price', '') }) }} <br>
        <span class="emobg-color-ink-light">{{ $t('tariff.fare_information.hour_fare_text') }}</span>
      </p>
      <p class="FareInformation__fare flex-grow-1">
        {{ $t('tariff.fare_information.day_fare_title') }}: {{ $t('tariff.information.price_per_day', { price: get(tariff, 'daily_price', '') }) }} <br>
        <span class="emobg-color-ink-light">{{ $t('tariff.fare_information.day_fare_text') }}</span>
      </p>
    </div>
  </DetailSectionComponent>
</template>
<style lang="scss" scoped>
.FareInformation {
  &__fare {
    flex-basis: 0;
  }
}
</style>
